import { FC, memo, MouseEvent } from "react"
import { MODIFIER_PRODUCTS_MINI } from "../../../../../utils/constants"
import { Button } from "../../../../ui/Button/Button"
import { ButtonArea } from "../../../../ui/Button/StyledButton"
import { Typography } from "../../../../ui/Typography/Typography"
import { Price } from "../../../parts"
import { ControlsButtonsContainer } from "../../../StyledProduct"
import { ModifierProductsType } from "../../../types"

export const ControlsProduct: FC<{
  isRemoved?: boolean
  removeMethod: () => void
  addMethod: () => void
  priceCalculate?: number
  isFetching?: boolean
  isAvailable?: boolean
  modifier?: ModifierProductsType
}> = memo(
  ({
    isRemoved,
    removeMethod,
    addMethod,
    modifier,
    priceCalculate,
    isFetching,
    isAvailable,
  }) => {
    const isMini = modifier === MODIFIER_PRODUCTS_MINI
    const removeHandler = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      removeMethod()
    }

    const addHandler = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      addMethod()
    }

    return (
      <ControlsButtonsContainer>
        {!isRemoved ? (
          <>
            {!isMini ? (
              <Button
                variant={"small"}
                icon={"Delete"}
                isHiddenBg
                onClick={removeHandler}
                isFetching={isFetching}
              >
                Удалить
              </Button>
            ) : (
              <Button
                as={"div"}
                variant={"translucent"}
                onClick={removeHandler}
                withArea={true}
                isFetching={isFetching}
              >
                <ButtonArea>
                  <Price variant={"total"} price={priceCalculate || 0} />
                </ButtonArea>
                <Typography variant={"span"}>Удалить</Typography>
              </Button>
            )}
          </>
        ) : (
          <>
            {!!isAvailable && (
              <>
                {!isMini ? (
                  <Button
                    variant={"small"}
                    icon={"Undo"}
                    isHiddenBg
                    onClick={addHandler}
                    isFetching={isFetching}
                  >
                    Вернуть
                  </Button>
                ) : (
                  <Button
                    as={"div"}
                    variant={"translucent"}
                    onClick={addHandler}
                    withArea={true}
                    isFetching={isFetching}
                  >
                    <ButtonArea>
                      <Price variant={"total"} price={priceCalculate || 0} />
                    </ButtonArea>
                    <Typography variant={"span"}>Вернуть</Typography>
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </ControlsButtonsContainer>
    )
  },
)

ControlsProduct.displayName = "ControlsProduct"
