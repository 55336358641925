import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useMutation } from "react-query"
import { fetchChangeQtySample } from "../api/cartAPI"
import { SampleType } from "../components/Products/types"
import { UnitMeasureType } from "../types/types"
import { ROUTES } from "../utils/constants"
import { SAMPLE_QUANTITY_LIMIT } from "../utils/helpers"
import { useCart } from "./cart/cart"
import { getCurrentSpecification } from "./product/helpers"
import { usePromocode } from "./promocode"

type UseSamplePropsType = {
  sample: SampleType
  options?: {
    initQty?: number
  }
}

type UseSampleReturnType = {
  path: string
  name?: string
  priceCalculate: number
  qtyInCart: number | null
  inCart: boolean
  isRemoved: boolean
  isFetching: boolean
  updateCurrentCount: (count: number) => void
  unitMeasure?: UnitMeasureType
  remove: () => void
  add: (isIncrement?: boolean) => void
  toggle: (e: MouseEvent<HTMLButtonElement>) => void
  sampleCount: number | null
  totalQty: number
  fastQty: number
  images: string[]
  counter: number | null
  isCountError: boolean
  isInit: boolean
  isDisabled: boolean
  baseTotalQty: number
}

type UseSampleType = (props: UseSamplePropsType) => UseSampleReturnType

export const useSample: UseSampleType = ({
  sample: {
    uuid,
    alias,
    unit,
    price: priceProduct = 0,
    total_qty = 0,
    fast_qty = 0,
    images = [],
    name,
  },
  options: { initQty } = {},
}) => {
  const {
    token,
    updateToken,
    updateSpecification,
    specification,
    updateTotalCost,
    products,
  } = useCart()
  const { updateDiscount } = usePromocode({ cart: token })

  const isLog = useRef<boolean>(false)

  const _getInitQty = useMemo(() => {
    return initQty || null
  }, [initQty])

  const [isInit, setIsInit] = useState<boolean>(false)
  const [counter, setCounter] = useState<number | null>(initQty || null)
  const [sampleCount, setSampleCount] = useState<number | null>(initQty || null)
  const [qtyInCart, setQtyInCart] = useState<number | null>(null)
  const [priceCalc, setPriceCalc] = useState<number>(priceProduct)
  const [isRemoved, setIsRemoved] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [flagRemove, setFlagRemove] = useState<boolean>(false)
  const [isCountError, setIsCountError] = useState(false)
  const [totalQty, setTotalQty] = useState<number>(0)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [productQtyInCart, setProductQtyInCart] = useState<number>(0)

  const currentSpecification = useMemo(
    () =>
      getCurrentSpecification({
        specification: specification,
        uuid: uuid || "",
      }),
    [specification, uuid],
  )

  const updateCurrentCount = useCallback(
    (count: number) => {
      setFlagRemove(count < 1)
      setCounter(count)
      if (count > totalQty) {
        // console.log("sample updateCurrentCount ")
        setIsCountError(true)
        return
      }
      setCounter(count)
      setSampleCount(count)
    },
    [totalQty],
  )

  const { mutate: changeQtySampleMutate } = useMutation(fetchChangeQtySample, {
    onSuccess: (response) => {
      if (token === null) {
        updateToken(response.cart)
      }

      if (uuid !== undefined) {
        if (response.quantity > 0) {
          updateSpecification({
            uuid: uuid,
            sample: response.quantity,
            isSampleRemoved: false,
          })
          setIsRemoved(false)
        } else {
          updateSpecification({
            uuid: uuid,
            sample: 0,
            isSampleRemoved: true,
          })
          setIsRemoved(true)
          setFlagRemove(false)
        }
      }

      updateTotalCost(response.total_cost)
      updateDiscount(response.discount || 0)
      setIsFetching(false)
    },
    onError: () => {
      setIsFetching(false)
    },
    onMutate: () => {
      setIsFetching(true)
    },
  })

  const addSampleToCart = useCallback(
    (isIncrement?: boolean) => {
      if (!uuid) {
        return
      }
      if (isDisabled) {
        return
      }

      const count = isIncrement ? (sampleCount || 0) + 1 : sampleCount || 0

      if (isLog.current) {
        console.log(
          "[toggleSampleToCart] countAdded ",
          count,
          " totalQty ",
          totalQty,
        )
      }

      if (count > totalQty) {
        setIsCountError(true)
        return
      }
      changeQtySampleMutate({
        product: uuid,
        cart: token || undefined,
        quantity: count,
      })
    },
    [changeQtySampleMutate, token, uuid, sampleCount, totalQty, isDisabled],
  )

  const removeSampleFromCart = useCallback(() => {
    if (!token || !uuid) {
      return
    }
    changeQtySampleMutate({
      product: uuid,
      cart: token || undefined,
      quantity: 0,
    })
  }, [changeQtySampleMutate, token, uuid])

  const toggleSampleToCart = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      if (isFetching || !isInit) {
        return
      }

      if (total_qty === 1) {
        return
      }

      if ((sampleCount || 0) > totalQty) {
        setIsCountError(true)
        removeSampleFromCart()
        return
      }

      if ((sampleCount || 0) < SAMPLE_QUANTITY_LIMIT) {
        addSampleToCart(true)
      } else {
        removeSampleFromCart()
      }
    },
    [
      addSampleToCart,
      isFetching,
      isInit,
      removeSampleFromCart,
      sampleCount,
      totalQty,
      total_qty,
    ],
  )

  useEffect(() => {
    if (isCountError) {
      setTimeout(() => {
        setIsCountError(false)
      }, 2000)
    }
  }, [isCountError])

  useEffect(() => {
    // console.log("currentSpecification sample ", currentSpecification)
    if (currentSpecification !== null) {
      setQtyInCart(currentSpecification?.sample || 0)
      setSampleCount(currentSpecification?.sample || _getInitQty)
      setIsRemoved(!!currentSpecification?.isSampleRemoved)
      setProductQtyInCart(
        !currentSpecification.isRemoved
          ? currentSpecification.quantity || 0
          : 0,
      )
    } else {
      setQtyInCart(null)
      setProductQtyInCart(0)
    }

    setIsInit(true)
  }, [currentSpecification, uuid, products, _getInitQty])

  useEffect(() => {
    if (!isInit) {
      return
    }
    const inCart = qtyInCart && !isRemoved
    const isNewCount = qtyInCart !== sampleCount
    // console.log("sampleCount changed ", sampleCount, " qtyInCart ", qtyInCart)
    if (inCart && isNewCount) {
      if (flagRemove) {
        removeSampleFromCart()
      } else {
        addSampleToCart()
      }
    }
  }, [
    addSampleToCart,
    flagRemove,
    isInit,
    isRemoved,
    qtyInCart,
    removeSampleFromCart,
    sampleCount,
  ])

  useEffect(() => {
    if (sampleCount !== null && sampleCount > 0) {
      setPriceCalc(priceProduct * sampleCount)
    }
  }, [sampleCount, priceProduct])

  useEffect(() => {
    setTotalQty(total_qty - productQtyInCart)
  }, [total_qty, productQtyInCart])

  useEffect(() => {
    const isMoreLimit = totalQty - (qtyInCart || 0) === 0
    const isTotalOne = total_qty <= 1
    const isNextNotRemoved = qtyInCart !== SAMPLE_QUANTITY_LIMIT

    setIsDisabled((isMoreLimit || isTotalOne) && isNextNotRemoved)
  }, [totalQty, total_qty, qtyInCart])

  return {
    priceCalculate: priceCalc,
    updateCurrentCount,
    isRemoved,
    isFetching,
    qtyInCart,
    inCart: !!qtyInCart,
    name: name || "",
    unitMeasure: unit,
    remove: removeSampleFromCart,
    add: addSampleToCart,
    toggle: toggleSampleToCart,
    sampleCount: sampleCount,
    path: `${ROUTES.product}/${alias}`,
    totalQty: totalQty,
    fastQty: fast_qty,
    images: images,
    counter,
    isCountError,
    isInit,
    isDisabled,
    baseTotalQty: total_qty || 0,
  }
}
