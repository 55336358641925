import type { FC } from "react"
import dynamic, { DynamicOptions } from "next/dynamic"
import { useAppSelector } from "../../../../hooks/redux"
import { useSample } from "../../../../hooks/sample"
import { useShippings } from "../../../../hooks/shippings/shippings"
import { WithViewProductVariantType } from "../../../../types/types"
import { MODIFIER_PRODUCTS_MINI } from "../../../../utils/constants"
import { dateToISOString } from "../../../../utils/helpers"
import { EntityImage } from "../../../ui/EntityImage/EntityImage"
import { IMAGE_CONFIG } from "../../constants"
import type { NoticeDiffDeliveryDatePropsType } from "../../parts"
import {
  Counter,
  DeliveryMessage,
  ImageTile,
  MACROS_ERROR_COUNT,
  Price,
  Title,
} from "../../parts"
import { ProductWrapper } from "../../ProductWrapper"
import { ProductContainer } from "../../StyledProduct"
import type { ModifierProductsType, SampleType } from "../../types"
import { ControlsProduct } from "../Product/Controls/ControlsProduct"

const NoticeDiffDeliveryDate = dynamic((() =>
  import("../../parts/NoticeDiffDeliveryDate").then(
    (mod) => mod.NoticeDiffDeliveryDate,
  )) as DynamicOptions<NoticeDiffDeliveryDatePropsType>)

export const Sample: FC<
  {
    sample: SampleType
    isShared?: boolean
    modifier?: ModifierProductsType
  } & WithViewProductVariantType
> = ({ sample, viewProductsVariant, isShared, modifier }) => {
  const minShippingDateCart = useAppSelector(
    (state) => state.cart.shipping.minDate.iso,
  )

  const {
    name,
    updateCurrentCount,
    priceCalculate,
    unitMeasure,
    isRemoved,
    remove,
    add,
    isFetching,
    sampleCount: currentCount,
    inCart,
    path,
    totalQty,
    fastQty,
    images,
    counter,
    isInit,
    isCountError,
    baseTotalQty,
  } = useSample({
    sample: sample,
    options: {
      initQty: sample.initQty,
    },
  })

  const { shippingDate } = useShippings({
    product: {
      totalQty: totalQty,
      currentCount: currentCount || 0,
      fastQty: fastQty,
    },
  })

  const modifierIsMini = modifier === MODIFIER_PRODUCTS_MINI

  return (
    <>
      <ProductWrapper
        viewProductsVariant={viewProductsVariant}
        isRemoved={isRemoved && !isShared}
        modifier={modifier}
      >
        <ProductContainer>
          {!modifierIsMini && (
            <NoticeDiffDeliveryDate
              shippingDate={
                !!shippingDate ? dateToISOString(shippingDate) : null
              }
              minShippingDateCart={minShippingDateCart}
              isAnalogs={false}
            />
          )}

          <ImageTile path={path}>
            <EntityImage
              imagePath={images[0]}
              imageAlt={name || ""}
              layout={"intrinsic"}
              width={IMAGE_CONFIG.list.size.width}
              height={IMAGE_CONFIG.list.size.height}
              objectFit={"cover"}
              quality={IMAGE_CONFIG.list.quality}
            />
          </ImageTile>
          <Title name={name || ""} path={path} />

          <Counter
            counter={counter}
            currentCount={currentCount}
            currentUnit={1}
            initialUnit={null}
            unitMeasure={unitMeasure}
            maxCount={totalQty}
            isFetching={isFetching}
            updateCountHandler={updateCurrentCount}
            productInCart={inCart}
            productIsRemove={isRemoved}
            isStatic={isShared && !inCart}
            isInitProduct={isInit}
            isCountError={isCountError}
            messageError={`Доступно только ${MACROS_ERROR_COUNT} образцов`}
          />

          {!modifierIsMini && (
            <>{!isShared && <DeliveryMessage deliveryDate={shippingDate} />}</>
          )}

          {!modifierIsMini && (
            <>
              <Price
                variant={"total"}
                price={priceCalculate}
                messageImportant={isRemoved ? undefined : "нет в наличии"}
              />
            </>
          )}

          <Price price={sample.price || 0} unitMeasure={"шт"} />

          {!isShared && (
            <ControlsProduct
              isRemoved={isRemoved}
              removeMethod={remove}
              addMethod={() => add(true)}
              priceCalculate={priceCalculate}
              isFetching={isFetching}
              isAvailable={!!baseTotalQty}
              modifier={modifier}
            />
          )}
        </ProductContainer>
      </ProductWrapper>
    </>
  )
}
