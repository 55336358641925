import React, { FC, memo } from "react"
import { WithViewProductVariantType } from "../../../types/types"
import { VIEW_PRODUCTS_CHECKOUT } from "../../../utils/constants"
import { Sample } from "../Cart/Sample/Sample"
import { Products } from "../Products"
import { ModifierProductsType, ProductSharedType } from "../types"

export const SamplesGrid: FC<
  {
    samples: ProductSharedType[]
    isShared?: boolean
    modifier?: ModifierProductsType
  } & WithViewProductVariantType
> = memo(
  ({
    samples,
    viewProductsVariant = VIEW_PRODUCTS_CHECKOUT,
    isShared,
    modifier,
  }) => {
    if (!samples.length) {
      return null
    }
    return (
      <Products view={viewProductsVariant} titleList={"Образцы"} withBorder>
        {samples.map((sample) => (
          <Sample
            key={sample.uuid}
            viewProductsVariant={viewProductsVariant}
            sample={sample}
            isShared={isShared}
            modifier={modifier}
          />
        ))}
      </Products>
    )
  },
)

SamplesGrid.displayName = "SamplesGrid"
